import { Button, Card, Col, Dropdown, Row } from 'antd';
import axios from 'axios';
import * as eva from 'eva-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import { toast } from '../../apollo';
import { DownArrow } from '../../assets/svg';
import { BREAKPOINTS, GA_EVENT, GA_LABEL } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import CommonDropdown from '../../components/CommonDropdown';
import {
  GET_AGENCY_DROPDOWN_LIST,
  GET_EQC_TYPE_DROPDOWN_LIST,
  GET_USER_DROPDOWN_LIST,
} from './graphql/Queries';

const IndividualReport = ({ dateSelected, fromDate, toDate }) => {
  const { getCurrentUser, getToken, getTenantUser } = useContext(AppContext);
  const tenantId = getTenantUser()?.tenant?.id;
  const currentUser = getCurrentUser();
  const token = getToken();
  const [userId, setUserId] = useState(null);
  const [agencyId, setAgencyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eqcTypeId, setEqcTypeId] = useState(null);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUrl = (
    fetchToken,
    fetchFromDate,
    fetchToDate,
    fetchUserId,
    fetchAgencyId,
    fetchEqcTypeId,
    type,
    endpoint,
    userTenantId,
  ) => {
    let url = `${process.env.REACT_APP_REPORT_SERVER_URL}${endpoint}?access_token=Bearer ${fetchToken}&fromDate=${fetchFromDate}&toDate=${fetchToDate}`;
    if (userTenantId) {
      url = `${url}&tenantId=${userTenantId}`;
    }
    if (type === 'user') {
      return fetchUserId ? `${url}&userId=${fetchUserId}` : url;
    }
    if (type === 'agency') {
      return fetchAgencyId ? `${url}&agencyId=${fetchAgencyId}` : url;
    }
    if (type === 'eqcType') {
      return fetchEqcTypeId ? `${url}&projectEqcTypeId=${fetchEqcTypeId}` : url;
    }
  };

  const downloadCsv = async (type, endpoint, userTenantId) => {
    if (!dateSelected) {
      return toast({
        message: 'Select the Date Range',
        type: 'error',
      });
    }
    setLoading(type);
    const fetchUrl = getUrl(
      token,
      fromDate,
      toDate,
      userId,
      agencyId,
      eqcTypeId,
      type,
      endpoint,
      userTenantId,
    );
    await axios
      .get(fetchUrl)
      .then(() => {
        let str = `Type: ${type}, From: ${fromDate}, To: ${toDate}`;
        if (type === 'user' && userId) {
          str += `, User Id: ${userId}`;
        }
        if (type === 'agency' && agencyId) {
          str += `, Agency Id: ${agencyId}`;
        }
        if (type === 'eqcType' && eqcTypeId) {
          str += `, Checklist Id: ${eqcTypeId}`;
        }
        Event(GA_EVENT.DOWNLOAD_INDIVIDUAL_REPORT, {
          label: GA_LABEL.DOWNLOAD_INDIVIDUAL_REPORT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          query: str,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        // eslint-disable-next-line no-undef
        window.location = fetchUrl;
      })
      .catch((error) => {
        if (error?.response?.status !== 200) {
          toast({
            message: error?.response?.data?.message,
            type: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const dropdownMenu = (type, dependency) => {
    const items = [
      {
        key: '1',
        label: (
          <div
            onClick={() =>
              downloadCsv(type, '/api/v1/export-csv/reports', tenantId)
            }
          >
            EQC Log (CSV)
          </div>
        ),
      },
      {
        key: '2',
        label: (
          <div
            onClick={() =>
              downloadCsv(
                type,
                '/api/v1/export-csv/report/export-latest-stages-eqcs',
              )
            }
          >
            EQC Latest Log(CSV)
          </div>
        ),
      },
      ...(dependency
        ? [
            {
              key: '3',
              label: (
                <div
                  onClick={() =>
                    downloadCsv(
                      type,
                      '/api/v1/export-csv/report/eqc-stages-status-counts',
                    )
                  }
                >
                  EQC Count (CSV)
                </div>
              ),
            },
          ]
        : []),
      ...(type !== 'eqcType'
        ? [
            {
              key: '4',
              label: (
                <div
                  onClick={() => {
                    downloadCsv(
                      type,
                      '/api/v1/export-csv/report/instruction/detail-log',
                      tenantId,
                    );
                  }}
                >
                  Instruction Detailed
                </div>
              ),
            },
            {
              key: '5',
              label: (
                <div
                  onClick={() => {
                    downloadCsv(
                      type,
                      '/api/v1/export-csv/report/instruction/latest-log',
                      tenantId,
                    );
                  }}
                >
                  Instruction Latest log
                </div>
              ),
            },
          ]
        : []),
    ];
    return items;
  };

  return (
    <div className="individual-reports">
      <Row gutter={[24, 24]}>
        <Col span={isDesktopViewport ? 8 : 24}>
          <Card className="individual-reports-user">
            <h3>User-wise Report</h3>
            <div className="d-flex align-center mb-15">
              <div className="reports-filters d-flex width-percent-100">
                <div className="filter-div">
                  <CommonDropdown
                    allowClear
                    showSearch
                    placeholder="All Users"
                    optionFilterProp="children"
                    onChange={(e) => {
                      if (e) {
                        setUserId(e);
                      } else {
                        setUserId();
                      }
                    }}
                    query={GET_USER_DROPDOWN_LIST}
                    fetchPolicy="network-only"
                    responsePath="reportUserDropdownList.data"
                    valuePath="id"
                    labelPath="name"
                    optionKey="users"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-end">
              <Dropdown menu={{ items: dropdownMenu('user', userId) }} on>
                <Button
                  className={
                    !isDesktopViewport
                      ? 'width-percent-100 justify-center '
                      : ''
                  }
                  shape="round"
                  type="primary"
                  loading={loading === 'user'}
                >
                  <span className="ml-10">Select Report</span>
                  <span>
                    <DownArrow className="ml-5" />
                  </span>
                </Button>
              </Dropdown>
            </div>
          </Card>
        </Col>
        <Col span={isDesktopViewport ? 8 : 24}>
          <Card className="individual-reports-agency">
            <h3>Agency-wise Report</h3>
            <div className="d-flex align-center mb-15">
              <div className="reports-filters d-flex width-percent-100">
                <div className="filter-div">
                  <CommonDropdown
                    allowClear
                    showSearch
                    placeholder="All Agencies"
                    optionFilterProp="children"
                    onChange={(e) => {
                      if (e) {
                        setAgencyId(e);
                      } else {
                        setAgencyId();
                      }
                    }}
                    query={GET_AGENCY_DROPDOWN_LIST}
                    fetchPolicy="network-only"
                    responsePath="reportAgencyDropdownList.data"
                    valuePath="id"
                    labelPath="name"
                    optionKey="agencies"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-end">
              <Dropdown menu={{ items: dropdownMenu('agency', agencyId) }}>
                <Button
                  className={
                    !isDesktopViewport
                      ? 'width-percent-100 justify-center '
                      : ''
                  }
                  shape="round"
                  type="primary"
                  loading={loading === 'agency'}
                >
                  <span className="ml-10">Select Report</span>
                  <span>
                    <DownArrow className="ml-5" />
                  </span>
                </Button>
              </Dropdown>
            </div>
          </Card>
        </Col>
        <Col span={isDesktopViewport ? 8 : 24}>
          <Card className="individual-reports-eqctype">
            <h3>Checklist-wise Report</h3>
            <div className="d-flex align-center mb-15">
              <div className="reports-filters d-flex width-percent-100">
                <div className="filter-div">
                  <CommonDropdown
                    allowClear
                    showSearch
                    placeholder="All Checklists"
                    optionFilterProp="children"
                    onChange={(e) => {
                      if (e) {
                        setEqcTypeId(e);
                      } else {
                        setEqcTypeId();
                      }
                    }}
                    query={GET_EQC_TYPE_DROPDOWN_LIST}
                    fetchPolicy="network-only"
                    responsePath="reportEqcTypeDropdownList.data"
                    valuePath="id"
                    labelPath={['name', 'project.uniqueCode']}
                    optionKey="agencies"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-end">
              <Dropdown menu={{ items: dropdownMenu('eqcType', eqcTypeId) }}>
                <Button
                  className={
                    !isDesktopViewport
                      ? 'width-percent-100 justify-center '
                      : ''
                  }
                  shape="round"
                  type="primary"
                  loading={loading === 'eqcType'}
                >
                  <span className="ml-10">Select Report</span>
                  <span>
                    <DownArrow className="ml-5" />
                  </span>
                </Button>
              </Dropdown>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default IndividualReport;
