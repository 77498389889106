import { useLazyQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import clsx from 'clsx';
import {
  debounce,
  filter,
  get,
  includes,
  map,
  trim,
  uniq,
  uniqBy,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { GlobeIcon } from '../assets/svg';
import { DROPDOWN_LIMIT, SCROLL_CONST } from '../common/constants';
import usePrevious from '../common/usePrevious';

let searchDebounce = null;

const INITIAL_CURRENT_PAGE = 0;
const CommonDropdown = (props) => {
  const {
    query,
    variables = {},
    responsePath,
    uniqueByLabel = 'id',
    valuePath,
    labelPath,
    optionKey,
    callback,
    fetchPolicy,
    hasSelectAll,
    showSearch,
    useEffectDeps = [],
    conditionToCheckBeforeQuery = true,
    onChange = () => {},
    isSelectedAll,
    currentPage: currPage = 0,
    className,
    customOptions = [],
    showExtraLabel = false,
    showExtraLoader = false,
    filterDataById = '',
    addExtraData,
    getPopupContainer,
    dropdownMatchSelectWidth,
    ...rest
  } = props;
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(currPage);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentProjectId, setCurrentProjectId] = useState(rest?.projectId);
  const prevRestProjectId = usePrevious(rest?.projectId);
  const [loading, setLoading] = useState(true);
  const [fetchListData] = useLazyQuery(query, {
    fetchPolicy,
    onCompleted: (res) => {
      const data = get(res, responsePath, []);
      if (currentPage === 0) {
        setListData(data);
      } else {
        setListData((oldData) => uniqBy([...oldData, ...data], valuePath));
      }
      if (listData && filterDataById) {
        setListData((oldData) =>
          filter(oldData, (item) => item?.id !== filterDataById),
        );
      }
      if (addExtraData) {
        setListData((oldData) => uniq([...oldData, ...addExtraData]));
      }
      if (currentProjectId !== prevRestProjectId) {
        setCurrentPage(0);
        setCurrentProjectId(rest?.projectId);
      } else {
        setCurrentPage((page) => page + 1);
      }
      setHasMore(data?.length >= DROPDOWN_LIMIT);
      if (typeof callback === 'function') {
        if (addExtraData) {
          callback(res, addExtraData);
        } else {
          callback(res);
        }
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });
  useEffect(() => {
    if (prevRestProjectId !== rest?.projectId) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest?.projectId]);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      setCurrentPage(INITIAL_CURRENT_PAGE);
      fetchListData({
        fetchPolicy,
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: INITIAL_CURRENT_PAGE * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDeps);

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      const obj = {
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText,
          },
        },
      };
      fetchListData(obj);
    }
  };
  const searchQuery = (search) => {
    setSearchText(trim(search));
    setCurrentPage(0);
    fetchListData({
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          search: trim(search),
          skip: 0,
          limit: DROPDOWN_LIMIT,
        },
      },
    });
  };
  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(trim(value));
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  const getLabel = (optionLabel, item) => {
    if (showExtraLabel) {
      return (
        <div className="d-flex align-center">
          {optionLabel}
          {item?.projectAgencies?.length < 1 && <GlobeIcon className="ml-10" />}
        </div>
      );
    }
    return optionLabel;
  };
  return (
    <div className="common-dropdown-wrapper position-relative width-percent-100">
      <Spin spinning={showExtraLoader && loading} size="small">
        <Select
          loading={loading}
          onPopupScroll={handleScroll}
          showSearch={showSearch}
          onSearch={showSearch && handleSearch}
          onClear={handleClear}
          onBlur={handleClear}
          className={clsx(
            className,
            hasSelectAll && 'has-select-all',
            !searchText && loading && 'no-tag-render',
          )}
          filterOption={(input, option) => {
            const user = get(option, 'data-name', '');
            return user?.toLowerCase()?.includes(trim(input?.toLowerCase()));
          }}
          getPopupContainer={(trigger) =>
            typeof getPopupContainer === 'function'
              ? getPopupContainer(trigger)
              : trigger.parentElement
          }
          onChange={(e) => {
            let records = [];
            if (typeof e === 'object') {
              records = filter(listData, (item) => {
                return includes(e, get(item, valuePath));
              });
            } else {
              records = filter(listData, (item) => {
                return e === get(item, valuePath);
              });
            }
            onChange(e, records);
            handleClear();
          }}
          popupMatchSelectWidth={dropdownMatchSelectWidth}
          options={[
            ...(!searchText && hasSelectAll && listData?.length
              ? [
                  {
                    value: 'all',
                    label: (
                      <span>
                        {isSelectedAll ? 'Selected All' : 'Select All'}
                      </span>
                    ),
                  },
                ]
              : []),
            ...map(
              uniqBy([...listData, ...customOptions], uniqueByLabel),
              (item, index) => {
                const optionValue = get(item, valuePath);
                const optionLabel =
                  typeof labelPath === 'object'
                    ? map(labelPath, (label) => get(item, label)).join(' - ')
                    : get(item, labelPath);
                return {
                  key: `${optionKey}-${optionValue}-${index}`,
                  value: optionValue,
                  label: getLabel(optionLabel, item),
                  'data-name': optionLabel,
                };
              },
            ),
          ]}
          {...rest}
        />
      </Spin>
    </div>
  );
};

export default CommonDropdown;
